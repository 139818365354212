<template>
  <div id="container"></div>
  <div class="div_title"><span style="color: #ff7f50;">인기상품</span></div>
  <div class="card main">
    <p class="agree">1. 해외직구로 주문이 많은 상품/브랜드의 상품들입니다.</p>
    <p class="agree">2. GEEK9에서 대행해 드리는 상품들입니다.</p>
    <p class="agree">3. 해외 사이트의 상품이기 때문에 검색/상세조회에 시간이 걸립니다.</p>
    <p class="agree">4. 해외 사이트의 상품이기 때문에 한번에 조회가 되지 않기도 합니다. 그럴 때는 새로고침(F5) 후에 다시 조회해 보시기 바랍니다.</p>
  </div>
  <div class="card main">
    <span style="display: flex;flex-wrap: wrap;">
      <div class="hot-list margin10" id="VINTED_BY_KEYWORD" @click="search({id:'VINTED_BY_KEYWORD', page:1})">[중고] Vinted - 축구유니폼</div>
      <div class="hot-list margin10" id="merciTotebag" @click="search({id:'merciTotebag'})">[신품] Merci - 에코백</div>
      <div class="hot-list margin10" >추가예정</div>
    </span>
  </div>
  <div class="margin10 no-result" v-if="resultJson.length === 0">
    선택된 상품이 없습니다
  </div>
  <div v-else class="shop-search-result">
    <div class="special-column" v-for="(item, index) in resultJson" :value="index" v-bind:key="index" >
      <div v-if="item.type === 'ads'">
        <div class="item-menu" />
        <div class="special-content">
          <table class="shop-table-value shop-table" >
            <tr>
              <td class="reg-img-container" >
                <Adsense
                  data-ad-client="ca-pub-6699354447815429"
                  data-ad-slot="9634068660"
                  data-ad-format="auto"
                  ins-style="display:block;"
                  data-full-width-responsive="true">
                </Adsense>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div v-else-if="pageId==='merciTotebag'">
        <div class="special-content">
          <table class="shop-table-value shop-table" >
            <tr>
              <td class="reg-img-container" >
                <img class="reg-img link" :src="item.imgUrl" @click="click({type:'detail', url:'https://merci-merci.com' + item.url})">
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div v-else-if="pageId==='VINTED_BY_KEYWORD'">
        <div class="special-content">
          <table class="shop-table-value shop-table" >
            <tr>
              <td class="reg-img-container" >
                <img class="reg-img link" :src="item.mainImgUrl" @click="click({type:'detail', item:item})">
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div style="text-align:center;" v-if="resultJson.length !== 0">
    <br>
    <Button @click="more({id:this.pageId})" label="더보기" raised />
  </div>
  <Modal v-model='isShow' style="z-index: 99999999;">
    <div class="modal">
      <div class="div_title" style="text-align: left;margin-top:-20px;">
        <span style="color: #ff7f50; ">상세정보</span>
      </div>
      <table id="shop-modal_table" class="shop-table" style="width:100%;">
        <tr>
          <td>상품명</td>
          <td>{{ title }}</td>
        </tr>
        <tr v-if="opt.length === 2">
          <td>현지가격</td>
          <td>{{ opt[0] }} : 19 euro <br> {{ opt[1] }} : 35 euro </td>
        </tr>
        <tr v-else>
          <td>현지가격</td>
          <td>{{ price }} euro</td>
        </tr>
        <tr>
          <td>현지배송비</td>
          <td v-if="pageId === 'merciTotebag'">7 euro</td>
          <td v-else-if="pageId === 'VINTED_BY_KEYWORD'">9 euro</td>
        </tr>
        <tr v-if="pageId === 'merciTotebag'">
          <td>해외배송비<br>(프랑스 > 한국)</td>
          <td>구매개수 상관없이 15,000원</td>
        </tr>
        <tr v-else-if="pageId === 'VINTED_BY_KEYWORD'">
          <td>해외배송비<br>(유럽 > 한국)</td>
          <td>포장이후 책정</td>
        </tr>
      </table>
      <br>
      <Carousel id="gallery" :items-to-show="1" :wrap-around="false" v-model="currentSlide">
        <Slide v-for="(imgUrl, index) in imgUrlList" :key="index" >
          <img style="max-height:300px;" class="carousel__item" :src="imgUrl"/>
        </Slide>
      </Carousel>
      <br>
      <div style="display:flex;align-items: center;text-align: center;justify-content: center;">
        <button class="shop-menu-navigator" @click="click({type:'left'})" style="left:0px;">&lt;</button>
        <label class="margin10" style="font-size:15px;">{{ currentSlide + 1 }} of {{ imgUrlList.length }}</label>
        <button class="shop-menu-navigator" @click="click({type:'right'})" style="right:0px;">&gt;</button>
      </div>
      <br>
      <Carousel id="thumbnails" :items-to-show="Math.min(imgUrlList.length, 4)" :wrap-around="true" v-model="currentSlide" ref="carousel" >
        <Slide v-for="(imgUrl, index) in imgUrlList" :key="index">
          <img style="max-height:100px;" :src="imgUrl" class="carousel__item" @click="slideTo(index)" />
        </Slide>
      </Carousel>
      <br>
      <div style="text-align: left;margin-top:-20px;">
        <div class="div_title" >
          <span style="color: #ff7f50;">예상견적</span>
        </div>
      </div>
      <div class="card main" >
        <table style="width:100%">
          <tr v-if="pageId === 'merciTotebag'" style="width:100%">
            <td class="padding-left" style="width:30%">
              <select v-model="modalPrice" class="normalInput" v-if="opt.length === 2" style="width:100%;">
                <option value = -1 selected>현지가격 선택</option>
                <option value = 19 > {{ opt[0] }} : 19 euro</option>
                <option value = 35 > {{ opt[1] }} : 35 euro</option>
              </select>
              <select v-model="modalPrice" class="normalInput" v-else style="width:100%">
                <option value = -1 selected>현지가격 선택</option>
                <option :value = Number(price) >{{ Number(price) }} euro</option>
              </select>
            </td>
            <td class="padding-left" style="width:30%">
              <input v-model="modalAmount" class="normalInput" placeholder="구매수량" type="number">
            </td>
            <td class="padding-left" style="width:20%">
              <input @click="click({type:'calc'})" class="button" type="button" value="견적계산" style="max-width:100px;float:right;"/>
            </td>
          </tr>
          <tr v-else-if="pageId === 'VINTED_BY_KEYWORD'" style="width:100%">
            <td class="padding-left" style="width:30%">
              <select v-model="modalPrice" class="normalInput" style="width:100%;" disabled>
                <option :value = 'Number(price) + 9' selected>{{ price + 9 }} euro</option>
              </select>
            </td>
            <td class="padding-left" style="width:30%">
              <input v-model="modalAmount" class="normalInput" placeholder="구매수량" type="number" disabled >
            </td>
            <td class="padding-left" style="width:20%">
              <input @click="click({type:'vinted', url:this.api.shortenUrl(url)})" class="button" type="button" value="견적계산" style="max-width:100px;float:right;"/>
            </td>
          </tr>
          <tr v-if="!(estimate === undefined || estimate === '')">
            <td v-if="pageId === 'merciTotebag'" style="height:30px;" colspan="3" class="margin10 no-result"><label style="color:red">{{ estimate }}원</label>(수수료 포함) + <label style="color:red">15,000원</label>(한국으로 보내는 배송비) = <label style="color:red">{{ estimateTotal }}원</label></td>
            <td v-if="pageId === 'VINTED_BY_KEYWORD'" style="height:30px;" colspan="3" class="margin10 no-result"><label style="color:red">{{ estimateTotal }}원 + 한국으로 보내는 배송비(포장이후 책정)</label></td>
          </tr>
        </table>
      </div>
      <br> 
      <div>
        <button class="modal_button" @click="click({type:'order'})" v-if="!(estimate === undefined || estimate === '')">주문</button>
        <button class="modal_button" @click="closeModal">닫기</button>
      </div>
    </div>
  </Modal>
    
  <div class="ad-container">
    <Adsense
      data-ad-client="ca-pub-6699354447815429" 
      data-ad-slot="8230887469"
      data-ad-format="horizontal"
      data-full-width-responsive="true">
    </Adsense>
  </div>
</template>
<script>

// import { useMeta } from 'vue-meta'
import router from './router'
import { ref, inject } from 'vue'
import { Carousel, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import ssr from './components/common/ssr'

export default {
  emits: ["setCookies", "setMenu"],
  setup() {
    const setMeta = inject('setMeta')
    const metaTitle = 'GEEK9 > 인기상품'
    const keywords = '직구,해외직구,구매대행,배송대행,배대지,해외운송,이사,geek9,인기상품,해외상품,중고,해외인기상품,vinted,carousell'
    const description = '해외 인기 상품 조회/견적 및 대행신청'
    const geek9Url = ssr.GEEK9_URL + 'hot'
    setMeta({
      title: metaTitle,
      keywords: keywords,
      description: description,
      'og:title': metaTitle,
      'og:description': description,
      'og:image': 'https://geek9.kr/img/logo_big.e0186dc8.jpg',
      'og:url': geek9Url,
      'canonical': geek9Url
    })
    // useMeta( {
    //   title: 'GEEK9 > 인기상품',
    //   link: [{rel: "canonical", href: 'https://geek9.kr/hot'}],
    //   meta: [
    //     { vmid: 'charset_hot', charset: 'utf-8' },
    //     { vmid: 'http-equiv_hot', 'http-equiv': 'X-UA-Compatible', content: 'IE=edge,chrome=1' },
    //     { vmid: 'company_hot', name: 'company', itemprop: 'name', content: 'GEEK9' },
    //     { vmid: 'keywords_hot', name: 'keywords', content:'직구,해외직구,구매대행,배송대행,배대지,해외운송,이사,geek9,인기상품,해외상품,중고,해외인기상품,vinted,carousell' },
    //     { vmid: 'viewport_hot', name: 'viewport', content: 'width=device-width' },
    //     { vmid: 'description_hot', name: 'description', content: '해외 인기 상품 조회/견적 및 대행신청' },
    //     { vmid: 'theme-color_hot', name: 'theme-color', content: '#ffa07a'},
    //     { vmid: 'og:site_name_hot', property: 'og:site_name', content: 'GEEK9'},
    //     { vmid: 'og:description_hot', property: 'og:description', content: '해외 인기 상품 조회/견적 및 대행신청'},
    //     { vmid: 'og:title_hot', property: 'og:title', content: 'GEEK9 > 인기상품'},
    //     { vmid: 'og:image_hot', property: 'og:image', content: 'https://geek9.kr/img/logo_big.e0186dc8.jpg'},
    //     { vmid: 'og:url_hot', property: 'og:url', content: 'https://geek9.kr/hot'}
    //   ]
    // })    
    const addJsonLd = () => {
      let flag = true
      document.querySelectorAll('script').forEach(el => {
        if (el.getAttribute('type') === 'application/ld+json' ) {
          flag = false
          return
        }
      })
      if(flag === true) {
        const script = document.createElement('script')
        script.setAttribute('type', 'application/ld+json')
        script.textContent = JSON.stringify(
          {
            "@context": "https://schema.org",
            "@graph": [
              ssr.getWebPage(geek9Url, title, ssr.GEEK9_URL, description),
            ]
          }
        )
        document.head.appendChild(script)
      }
    }
    const isShow = ref(false)
    let title = ref('')
    let price = ref('')
    let url = ref('')
    let opt = ref([])
    let imgUrlList = ref([])

    function showModal(param) {
      const pageId = param.pageId
      if(pageId === 'merciTotebag') {
        title.value = param.title
        price.value = param.price
        opt.value = String(param.opt).split(',')
        url.value = param.url
        imgUrlList.value = param.imgUrl
      } else if(pageId === 'VINTED_BY_KEYWORD') {
        title.value = param.title
        price.value = Number(param.sellPrice)
        url.value = param.url
        imgUrlList.value = [param.mainImgUrl]
      }
      isShow.value = true
    }
    function closeModal () {
      isShow.value = false
    }
    return {
      isShow, closeModal, showModal, title, price, imgUrlList, opt, url, addJsonLd
    }
  },
  components: {
    Carousel, Slide
  },
  mounted() {
    this.addJsonLd()
    this.emitter.on('hotReload', this.reload)
    this.api.setPage('hot')
    this.$emit("setMenu", {page:'hot'})
    console.log(this.api.getCookies('page'))
  },
  unmounted() {
    this.emitter.off('hotReload', this.reload)
  },
  data() { 
    return { 
      estimate:'',
      estimateTotal:'',
      modalPrice:-1,
      modalAmount:undefined,
      offset:9,
      currentSlide: 0,
      pageId:'',
      // pageId:'merciTotebag',
      hotKey: -1,
      page:1,
      resultJson:[],
      // resultJson:[{url:'/products/tote-bag-en-coton-merci-rose-ecru?variant=43502067089664', imgUrl:'https://merci-merci.com/cdn/shop/products/grd-tote-rose_1000x1000.jpg?v=1671628685'}]
      merciJson:[],
      vintedJson:[],
      merciIdx:0,
      vintedParam:{},
      vintedIdx:0
    }
  },
  methods: {
    async search(param) {
      this.clearJson()
      this.emitter.emit('dialog', {flag:true, title:'loading'})
      this.pageId = param.id
      const page = param.page
      if(page !== undefined) this.page = param.page
      const hotList = document.getElementsByClassName('hot-list')
      for(let i = 0; i < hotList.length; i++) {
        const item = hotList[i]
        item.style.backgroundColor = 'white'
        item.style.color = 'black'
      }
      const selectedItem = document.getElementById(this.pageId)
      selectedItem.style.backgroundColor = 'lightsalmon'
      selectedItem.style.color = 'white'

      await this.api.axiosGql(this.pageId, {type:this.pageId, page:page, keyword:'uniforme football'})
      .then( async (res) => {
        if(this.pageId === 'merciTotebag') this.merciJson = res.data
        else if(this.pageId === 'VINTED_BY_KEYWORD') this.vintedJson = res.data
        if(res.status === 200) await this.more({id:this.pageId})
        else alert('새로고침(F5) 후에 다시 시도해주세요')
      }).catch( (err) => {
        console.log(err)
        alert('새로고침(F5) 후에 다시 시도해주세요')
      }).finally( ()=> {
        this.emitter.emit('dialog', {flag:false, title:'loading'})
      })
    },
    clearJson() {
      this.merciJson = []
      this.pcbJson = []
      this.resultJson = []
      this.vintedIdx = 0
      this.merciIdx = 0
    },
    async click(param) {
      const type = param.type
      if(type === 'detail') {
        if(this.pageId === 'merciTotebag') {
          const url = param.url
          this.emitter.emit('dialog', {flag:true, title:'loading'})
          await this.api.axiosGql(this.pageId + '-' + type, {type:this.pageId + '-' + type, url:url})
          .then( async (res) => {
            let result = res.data
            result.url = url
            result.pageId = this.pageId
            if(res.status === 200) {
              this.estimate = ''
              this.estimateTotal = ''
              this.showModal(result)
            } else alert('새로고침(F5) 후에 다시 시도해주세요')
          }).catch( (err) => {
            console.log(err)
            alert('새로고침(F5) 후에 다시 시도해주세요')
          }).finally( ()=> {
            this.emitter.emit('dialog', {flag:false, title:'loading'})
          })
        } else if(this.pageId === 'VINTED_BY_KEYWORD') {
          let item = param.item
          item.pageId = this.pageId
          this.modalAmount = 1
          this.modalPrice = Number(item.sellPrice) + 9
          this.estimate = ''
          this.estimateTotal = ''
          this.showModal(item)
        }
      } else if(type === 'left') {
        if(this.currentSlide === 0) this.currentSlide = this.imgUrlList.length - 1
        else this.currentSlide--
      } else if(type === 'right') {
        if(this.currentSlide === this.imgUrlList.length - 1) this.currentSlide = 0
        else this.currentSlide++
      } else if(type === 'calc') {
        if(this.modalPrice === -1 ) alert('현지가격을 선택해주세요.')
        else if(this.modalAmount === undefined || this.modalAmount <= -1) alert('구매수량을 올바르게 입력해주세요. : ')
        else {
          this.emitter.emit('dialog', {flag:true, title:'loading'})
          const param = {'type': 'calcBuy', 'country': '프랑스환율', 'sellPrice': (this.modalPrice*this.modalAmount), 'delivery': 7, 'etc': 0};
          
          await this.api.axiosGql('calcBuy', param) 
          .then((res) => {
            let won = res.data.result
            let wonInt = parseInt(won.toString())
            this.estimate = wonInt.toLocaleString()
            this.estimateTotal = (wonInt + 15000).toLocaleString()
          }) 
          .catch(() => { 
            this.estimate = ''
            this.estimateTotal = ''
            alert('다시 시도해주세요')
          }) 
          .finally(() => { 
            this.emitter.emit('dialog', {flag:false, title:'loading'})
          })
        }
      } else if(type === 'order') {
        let json = {}
        if(this.pageId === 'merciTotebag') {
          if(confirm(this.modalAmount + '개에 대한 구매대행의 실제 견적을 받으시겠습니까?') ) {
            this.closeModal()
            json = { 
              URL: this.url, 
              NAME: this.title, 
              ORIGIN_PRICE:this.modalPrice+7, 
              COUNTRY:'프랑스', 
              IMG_URL: this.imgUrlList[0].indexOf('https:') === -1 ? 'https:' + this.imgUrlList[0] : this.imgUrlList[0], 
              AMOUNT:this.modalAmount, 
              ORDER_NUMBER:1, 
              WON_PRICE:''
            } 
          } else return
        } else if(this.pageId === 'VINTED_BY_KEYWORD') {
          if(confirm((this.modalPrice) + 'euro * ' + this.modalAmount + '개에 대한 구매대행의 실제 견적을 받으시겠습니까?') ) {
            this.closeModal()
            json = this.vintedParam
          } else return
        } else return
        const list = []
        list.push(json)
        router.push({ name: "order", params: {'list':JSON.stringify(list)} })
      } else if(type === 'vinted') {
        this.emitter.emit('dialog', {flag:true, title:'loading'})
        await this.api.axiosGql(type, param) 
        .then((res) => { 
          console.log(res)
          if(res.data.error !== undefined) {
            alert(res.data.error)
          } else {
            if(res.status === 200) {
              let won = res.data.sellPrice
              let wonInt = parseInt(won.toString())
              this.estimate = wonInt.toLocaleString()
              this.estimateTotal = wonInt.toLocaleString()
              
              this.vintedParam = { 
                URL: res.data.url, 
                NAME: res.data.name, 
                ORIGIN_PRICE:this.modalPrice, 
                COUNTRY:res.data.country, 
                IMG_URL:res.data.imgUrl, 
                AMOUNT:1, 
                ORDER_NUMBER:1, 
                WON_PRICE:wonInt
              } 
            } else alert(res.data)
          }
        }) 
        .catch((error) => { 
          alert(error)
          console.log(error)
        }) 
        .finally(() => { 
          this.emitter.emit('dialog', {flag:false, title:'loading'})
        })
      }
    },
    async more(param) {
      const type = param.id
      this.emitter.emit('dialog', {flag:true, title:'loading'})
      
      if(type === 'merciTotebag') {
        let startIdx = this.merciIdx 
        let isValid = true

        for(let i = startIdx; i < (startIdx+this.offset); i++) {
          let item = this.merciJson[i]
          if(item === undefined) {
            alert('마지막 페이지입니다')
            this.emitter.emit('dialog', {flag:false, title:'loading'})
            break
          } else {
            this.resultJson.push(item)
            this.merciIdx++
          }
        }
        if(isValid) {
          const adsIdx = Number(Math.floor(Math.random() * this.offset))
          this.resultJson[this.merciIdx-adsIdx-1] = {type:'ads'}
        }
      } else if(type === 'VINTED_BY_KEYWORD') {
        let startIdx = this.vintedIdx 
        let isValid = true

        for(let i = startIdx; i < (startIdx+this.offset); i++) {
          let item = this.vintedJson[i]
          if(item === undefined) {
            alert('마지막 페이지입니다')
            this.emitter.emit('dialog', {flag:false, title:'loading'})
            break
          } else {
            this.resultJson.push(item)
            this.vintedIdx++
          }
        }
        if(isValid) {
          const adsIdx = Number(Math.floor(Math.random() * this.offset))
          this.resultJson[this.vintedIdx-adsIdx-1] = {type:'ads'}
        }
      }

      this.emitter.emit('dialog', {flag:false, title:'loading'})
    },
    // async request() {
    //   window.Kakao.API.request({
    //     url: '/v2/user/me',
    //     success: async (response) => {
    //       let flag = false
    //       await this.api.axiosGql('SELECT_COMMON_USER', {type: 'SELECT_COMMON_USER', id:response.id })
    //       .then( async (res) => {
    //         const result = res.data
    //         if(result === 'null' || result === undefined || result === null ) flag = false
    //         else flag = true
    //       })
    //       .catch((err) => {
    //         console.log(err)
    //       })
    //       if(flag) {
    //         this.emitter.emit("setCookies", {login:true, id:response.id, nickname:response.properties.nickname, img:response.properties.thumbnail_image, mail:response.kakao_account.email})
    //       } 
    //       // else this.showRegistModal(response.id)
    //     },
    //     fail: (error) => {
    //       console.log(error)
    //     }
    //   })
    // },
    // async login() {
    //   window.Kakao.Auth.login({
    //     success: () => {
    //       this.tmp = '로그인'
    //       this.request()
    //       this.reload('dashboard')
    //     },
    //     fail: () => {
    //       this.$notify("다시 시도해주세요.")
    //     },
    //   })
    // }, 
    // logout() {
    //   window.Kakao.Auth.logout(() => {
    //     this.emitter.emit("setCookies", {login:false, id:'', nickname:'',img:'', mail:'', comnum:'', comname:''})
    //     this.tmp = 'not'
    //     this.$notify('로그아웃 되었습니다.')
    //     this.$refs.countdown.style.visibility = 'hidden'
    //   })
    // },
    reload(flag) {
      if(flag === 'dashboard') {
        this.hotKey++
        this.$emit("setMenu", {page:'hot'})
      }
    },
    slideTo(val) {
      this.currentSlide = val
    },
  }
}
</script>

<style scoped>
.hot-list {
  width:fit-content;
  cursor: pointer;
  /* background-color: lightsalmon; */
  border:1px solid lightsalmon;
  color:black;
  /* font-weight: bold; */
  padding:7px;
  border-radius: 16px;
  vertical-align: middle;
}
</style>